import React from "react";
import goalImage from "../../assets/goal.png";
import { Slide, Zoom } from "react-awesome-reveal";

const OurGoal = () => {
  return (
    <div className="bg-white w-[90%] mx-auto">
      <div className="w-[100%] grid lg:grid-cols-2 gap-x-20 gap-y-10">
        {/* Mobile Title */}
        <h2 className="text-center block lg:hidden text-[32px] text-[#0E7570] font-bold lg:mt-4">
          Our Goal
        </h2>

        {/* Image Section */}
        <Slide triggerOnce direction="left">
          <div className="w-[100%] order-1 flex justify-center lg:justify-start">
            <img
              className="w-[100%] object-contain"
              src={goalImage}
              alt="goalImage"
            />
          </div>
        </Slide>

        {/* Text Content */}
        <div className="order-2 flex flex-col gap-4 justify-center items-center lg:items-start lg:justify-start">
          {/* Desktop Title */}
          <div className="hidden lg:block">
            <Zoom triggerOnce>
              <h2 className="text-[32px] text-[#0E7570] font-bold lg:mt-4">
                Our Goal
              </h2>
            </Zoom>
          </div>
          <Zoom triggerOnce>
            <p className="whitespace-normal break-words text-[#0A0A0A] text-[18px] font-normal">
              Welcome to the future of coffee! We are excited to introduce our
              revolutionary coffee shop, aiming to bring together coffee
              enthusiasts and blockchain technology. With our token, you can
              enjoy exclusive perks, loyalty rewards, and participate in our
              growing coffee community.
            </p>
            <p className="whitespace-normal break-words text-[#0A0A0A] text-[18px] font-normal mt-6">
              Our goal is to make your coffee experience not just a daily
              routine, but an interactive and rewarding journey. From earning
              rewards with every purchase to supporting sustainable and ethical
              coffee farming, we're here to redefine the world of coffee one
              token at a time.
            </p>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default OurGoal;
