import React from "react";
import PageNotFoundImg from "../assets/pageNotFound.png";
import rightArrow from "../assets/rightArrow.svg";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex flex-col items-center">
        <img src={PageNotFoundImg} alt="pageNotFound" />

        <p className="text-[25px] sm:text-[60px] font-semibold">
          Page Not Found
        </p>
        <Link
          to="/"
          className="bg-[#0E7570] hover:bg-[#104543] text-[white] px-4 py-2 flex items-center justify-center gap-2 rounded-[6px]"
        >
          Go Back To Home
          <img className="pt-[3px]" src={rightArrow} alt="rightArrow" />
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
