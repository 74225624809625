import React from "react";
import cuffi from "../assets/cuffi-white.svg";
import linkedIn from "../assets/linkedInFooter.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import insta from "../assets/insta.svg";
import fingo from "../assets/fingo.svg";
import line from "../assets/line.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="bg-[#004442] pt-6 pb-3 w-full">
        <div className="w-[98%] m-auto flex flex-col  sm:flex-row  gap-4 justify-center">
          <div className=" flex justify-center sm:justify-start">
            <Link to="/">
              <img src={cuffi} alt="cuffi" />
            </Link>
          </div>
          <p className="relative top-5  border-t border-[#CECECE] hidden sm:block  sm:w-[25%]  md:w-[45%] lg:w-[55%] xl:w-[60%] "></p>
          <div className="flex justify-center sm:justify-start gap-2 sm:gap-4">
            <img src={linkedIn} alt="linkedIn" />
            <img src={facebook} alt="fb" />
            <img src={twitter} alt="twitter" />
            <img src={insta} alt="insta" />
            <img src={fingo} alt="fingo" />
          </div>
        </div>

        <div className="text-[#FFFFFF] font-medium text-[12px] leading-[14.52px] py-4 w-[86%] m-auto   flex flex-col md:flex-row gap-4 md:gap-0 items-center md:justify-between">
          <p>Copyright ©2024 Cuffi. All rights reserved.</p>
          <p>Privacy Notice | status | Cookie Preferences</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
