import React from "react";
import forward from "../assets/forward.svg";
import backward from "../assets/backward.svg";

const Pagination = ({ datalength, page, setPage, limit }) => {
  const totalPages = Math.ceil(datalength / limit);
  const visiblePages = [];

  let startPage = Math.max(page - 0, 1);
  let endPage = Math.min(page + 0, totalPages);

  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  return (
    <>
      <div className="flex justify-end mr-10 mt-5 gap-2">
        <span
          className={`flex items-center p-y px-3 border rounded-[6px] text-[#1A1A1A]  cursor-pointer
            ${page === 1 ? "cursor-not-allowed opacity-50" : ""}
          `}
          onClick={page > 1 ? () => setPage(page - 1) : null}
        >
          <img src={backward} alt="backward" />
        </span>

        {visiblePages.map((p) => (
          <span
            key={p}
            className={`px-3 py-2 border rounded-[6px] text-[#1A1A1A]  cursor-pointer
              ${page === p ? "bg-[#CDCDCD]" : ""}
            `}
            onClick={() => setPage(p)}
          >
            {p}
          </span>
        ))}

        <span
          className={`flex item-center  py-2 px-3 border rounded-[6px] text-[#1A1A1A]  cursor-pointer
            ${page === totalPages ? " cursor-not-allowed opacity-50" : ""}
          `}
          onClick={page < totalPages ? () => setPage(page + 1) : null}
        >
          <img src={forward} alt="forward" />
        </span>
      </div>
    </>
  );
};

export default Pagination;
