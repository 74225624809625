import React, { useEffect, useState, useRef } from "react";
import MetaMaskSDK from "@metamask/sdk";
import crossIcon from "../assets/cross.svg";
import { useNavigate } from "react-router-dom";
import metamaskIcon from "../assets/metamask-icon.svg";
import MetamaskAlert from "./clientDashboard/MetamaskAlert";
import { ethers } from "ethers";
import Loader from "./Loader";
import useMetaMask from "./clientDashboard/useMetaMask";

const PopUp = ({ open, setOpen }) => {
  const {
    defaultAccount,
    userBalance,
    isLoading,
    error,
    connectWallet,
    handleDisconnect,
    alert,
    switchOrAddAmoyNetwork,
  } = useMetaMask();

  const navigate = useNavigate();

  const togglePopup = () => {
    setOpen(!open);
  };

  return (
    <div>
      {open && (
        <div
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50"
          onClick={togglePopup}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-[300px] relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-gray-500 mt-2 mr-2"
              onClick={togglePopup}
            >
              <img src={crossIcon} />
            </button>
            <div className="flex flex-col items-center gap-6 justify-center py-12">
              <div className="w-[30%]">
                <img src={metamaskIcon} alt="metaMask" />
              </div>

              <button
                onClick={() => {
                  connectWallet();
                  togglePopup();
                }}
                className="bg-[#0E7570]  hover:bg-[#004442] text-white text-[18px] px-5 py-2 border rounded-[10px]"
              >
                Connect to MetaMask
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        <MetamaskAlert alert={alert} />
      </div>
    </div>
  );
};

export default PopUp;
