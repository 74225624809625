import React, { useEffect } from "react";
import HeroSection from "./HeroSection";
import MainLayout from "../../components/MainLayout";
import TokenEconomics from "./TokenEconomics";
import EstimateRewards from "./EstimateRewards";
import IncomeStoring from "./IncomeStoring";
import P2PService from "./P2PService";
import CuffiPortfolio from "./CuffiPortfolio";
import ProportionalRewards from "./ProportionalRewards";
import FrequentlyAskQuestions from "./FrequentlyAskQuestions";
import TokenLockerApp from "../../components/clientDashboard/TokenLock";
import Roadmap from "./RoadMap";

const Index = () => {
  return (
    <MainLayout>
      <div className="flex flex-col gap-20 bg-white">
        <div>
          <HeroSection />
        </div>
        <div>
          <TokenEconomics />
        </div>
        <div>
          <CuffiPortfolio />
        </div>
        <div>
          <EstimateRewards />
        </div>
        <div>
          <IncomeStoring />
        </div>
        <div>
          <Roadmap />
        </div>
        <div>
          <ProportionalRewards />
        </div>
        <div>
          <FrequentlyAskQuestions />
        </div>
      </div>
    </MainLayout>
  );
};

export default Index;
