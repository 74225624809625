import React from "react";

const RoadmapItem = ({ time, tasks, isLeft }) => {
  const { month, monthValue, year } = time;

  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();

  let dotStyle = "bg-gray-400";
  let dotAnimation = "";
  let borderAnimation = "";
  let dotSizeClass = "w-4 h-4";

  if (
    year < currentYear ||
    (year === currentYear && monthValue < currentMonth)
  ) {
    dotStyle = "bg-[#66A8A5]";
  } else if (year === currentYear && monthValue === currentMonth) {
    dotStyle = "bg-[#66A8A5]";
    dotAnimation = "animate-scaling";
    borderAnimation = "";
    dotSizeClass = "w-6 h-6 ";
  }

  return (
    <div className="relative flex w-full mb-20 lg:mb-0">
      {/* Dot */}
      <div className="block border-8 border-[#ECF4F4] rounded-full absolute lg:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
        <div
          className={`${dotSizeClass} ${dotStyle} border-2 border-[#0E7570] rounded-full z-20 ${dotAnimation}`}
        ></div>
      </div>
      <div
        className={`hidden lg:block absolute top-1/2 transform -translate-y-1/2 h-0.5 bg-gray-300 ${
          isLeft
            ? "right-1/2 w-[calc(50%-2rem)]"
            : "left-1/2 w-[calc(50%-2rem)]"
        }`}
      ></div>
      {/* Roadmap Card */}
      <div
        className={`bg-roadMap-Card bg-no-repeat bg-cover bg-center shadow-lg border border-[#0E75704D] rounded-lg py-6 px-8 relative w-[290px] sm:w-[300px] md:w-[400px] xl:w-[500px] ${
          isLeft
            ? "m-auto lg:ml-0 lg:mr-auto text-left"
            : "m-auto lg:mr-0 lg:ml-auto text-left"
        } ${borderAnimation}`}
      >
        <h3 className="text-lg font-semibold text-[#0F8580] mb-4 text-center md:text-left">
          {month}, {year}
        </h3>
        <ul className="list-disc list-outside text-[#0A0A0A] text-[16px] font-normal">
          {tasks.map((task, index) => (
            <li key={index} className="mb-1">
              {task}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RoadmapItem;
