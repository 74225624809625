import React, { useEffect, useState } from "react";
import cuffi from "../../assets/CUFFI.svg";
import { Link, useNavigate, Outlet, useLocation } from "react-router-dom";
import logout from "../../assets/logOut.svg";
import bell from "../../assets/bell.svg";
import menuIcon from "../../assets/Menu.svg";
import NavItem from "../NavItem";
import FileIcon from "../svg/File";
import DaoIcon from "../svg/Dao";
import InvestmentIcon from "../svg/Investment";
import HistoryIcon from "../svg/History";
import useMetaMask from "./useMetaMask";

const DashboardLayout = () => {
  const { defaultAccount, isLoading, error, connectWallet, handleDisconnect } =
    useMetaMask();
  const navigate = useNavigate();
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [walletAddress, setWalletAddress] = useState(
    localStorage.getItem("address") || ""
  );

  const [userBalance, setUserBalance] = useState(
    localStorage.getItem("balance") || ""
  );

  useEffect(() => {
    const updateWalletInfo = () => {
      const newAddress = localStorage.getItem("address");
      const newBalance = localStorage.getItem("balance");

      setWalletAddress(newAddress || "");
      setUserBalance(newBalance || "");
    };

    window.addEventListener("storage", updateWalletInfo);

    return () => {
      window.removeEventListener("storage", updateWalletInfo);
    };
  }, []);

  // navigation
  useEffect(() => {
    if (!walletAddress) {
      navigate("/");
    }
  }, [walletAddress]);

  //  window size
  const handleResize = () => {
    const currentWidth = window.innerWidth;
    setWindowWidth(currentWidth);

    if (currentWidth > 768) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [activeNavName, setActiveNavName] = useState("/dashboard");
  useEffect(() => {
    if (location.pathname) {
      setActiveNavName(location.pathname);
    }
  }, [location.pathname]);

  const NavItems = [
    {
      link: "/dashboard",
      title: "Investment",
      iconBlack: (
        <div className="">
          <InvestmentIcon fill="#66666E" />
        </div>
      ),
      iconWhite: (
        <div className="">
          <InvestmentIcon fill="#FFFFFF" />
        </div>
      ),
      name: "/dashboard",
      activeNavName,
      toggle,
      setToggle,
    },
    {
      link: "/dashboard/history",
      title: "History",
      iconBlack: (
        <div className="">
          <HistoryIcon fill="#66666E" />
        </div>
      ),
      iconWhite: (
        <div className="">
          <HistoryIcon fill="#FFFFFF" />
        </div>
      ),
      name: "/dashboard/history",
      activeNavName,
      toggle,
      setToggle,
    },

    {
      link: "/dashboard/dao",
      title: "DAO",
      iconBlack: (
        <div className="">
          <DaoIcon fill="#66666E" />
        </div>
      ),
      iconWhite: (
        <div className="">
          <DaoIcon fill="#FFFFFF" />
        </div>
      ),
      name: "/dashboard/dao",
      activeNavName,
      toggle,
      setToggle,
    },
    {
      link: "/dashboard/reports",
      title: "Reports",
      iconBlack: (
        <div className="">
          <FileIcon fill="#66666E" />
        </div>
      ),
      iconWhite: (
        <div className="">
          <FileIcon fill="#FFFFFF" />
        </div>
      ),
      name: "/dashboard/reports",
      activeNavName,
      toggle,
      setToggle,
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("address");
    localStorage.clear();
    navigate("/");
  };

  if (!walletAddress) {
    return null;
  }

  return (
    <div className="relative h-screen grid grid-cols-1  md:grid-cols-[30%_70%] lg:grid-cols-[21%_79%] xl:grid-cols-[20%_80%] ">
      <div
        className={`${
          toggle && windowWidth < 768
            ? " absolute left-0 top-0 transition-all  w-full bg-transparent h-screen z-40"
            : windowWidth < 768
            ? "absolute left-[-100%] transition-all"
            : ""
        } `}
        onClick={() => setToggle(!toggle)}
      >
        <div
          className="bg-white h-screen w-[62%] md:w-[100%] rounded-sm    md:z-0  flex flex-col justify-between items-center py-10 px-4 border-r-2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="pb-3">
            <div className="w-full flex justify-center">
              <Link to="/">
                <img
                  className="w-[80%] lg:w-full"
                  src={cuffi}
                  alt="cuffiIcon"
                />
              </Link>
            </div>

            <div className="mt-20">
              <ul className="flex flex-col gap-8 cursor-pointer w-[90%] m-auto">
                {NavItems.map((item, index) => (
                  <li key={index}>
                    <NavItem {...item} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <button
              onClick={handleLogout}
              className=" bg-[#EEEEF0] text-[#646464] flex gap-6 pl-4 pr-10 sm:pr-20 py-2 rounded-[10px] border-[1px]"
            >
              <img src={logout} alt="logout" /> Logout
            </button>
          </div>
        </div>
      </div>
      {/* 2div */}
      <div className="h-screen overflow-auto">
        <div className="sticky top-0 z-20 bg-white w-full h-20  flex flex-row items-center border-b justify-between px-6">
          <div className="block md:hidden" onClick={() => setToggle(!toggle)}>
            <img src={menuIcon} alt="menuIcon" />
          </div>
          <h2 className="hidden md:block text-[24px] font-bold leading-[29.05px]">
            Dashboard
          </h2>
          <div className="flex gap-4">
            <img src={bell} alt="bell" />
            {walletAddress ? (
              <div className="flex gap-2 items-center">
                <div className="flex flex-col">
                  {walletAddress && (
                    <span className="text-[#0E7570] font-semibold">
                      Wallet Address
                    </span>
                  )}
                  <span className="text-[#0A0A0A] text-[14px] font-semibold leading-[16.94px]">
                    {`${walletAddress?.substring(
                      0,
                      6
                    )}...${walletAddress?.substring(
                      walletAddress?.length - 6
                    )}`}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="p-2 lg:p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
