import { MetaMaskSDK } from "@metamask/sdk";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useMetaMask = () => {
  const [ethereum, setEthereum] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState("");
  const [userBalance, setUserBalance] = useState("");
  const [signer, setSigner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();

  const initializeSDK = async () => {
    try {
      const options = {
        useDeeplink: true,
        injectProvider: true,
        dappMetadata: {
          name: "Your DApp Name",
          url: window.location.href,
        },
        timeout: 15000,
      };

      const MMSDK = new MetaMaskSDK(options);
      const provider = await Promise.race([
        MMSDK.getProvider(),
        new Promise((_, reject) =>
          setTimeout(
            () => reject(new Error("SDK initialization timeout")),
            10000
          )
        ),
      ]);

      return provider;
    } catch (error) {
      console.error("SDK initialization error:", error);
      throw error;
    }
  };

  useEffect(() => {
    const setup = async () => {
      try {
        if (typeof window.ethereum !== "undefined") {
          console.log("Ethereum provider detected on mobile");
          setEthereum(window.ethereum);
        } else {
          console.log(
            "No Ethereum provider found, initializing MetaMaskSDK..."
          );
          const provider = await initializeSDK();
          if (provider) {
            setEthereum(provider);
          }
        }
      } catch (error) {
        console.error("Setup error:", error);
        setError("Failed to initialize MetaMask");
      }
    };
    setup();
  }, []);

  const reinitializeProvider = async () => {
    try {
      if (!ethereum) {
        console.log("Reinitializing Ethereum provider...");
        const provider = await initializeSDK();
        setEthereum(provider);
      }
    } catch (error) {
      console.error("Failed to reinitialize provider:", error);
    }
  };

  useEffect(() => {
    if (ethereum) {
      ethereum.on("disconnect", () => {
        console.log(
          "Ethereum provider disconnected. Attempting to reconnect..."
        );
        reinitializeProvider();
      });
    }
  }, [ethereum]);

  const detectNetwork = async (provider) => {
    let retryCount = 3;
    const retryDelay = 2000;

    while (retryCount > 0) {
      try {
        // Create Web3Provider with more lenient settings for mobile
        const ethersProvider = new ethers.providers.Web3Provider(provider, {
          timeout: 10000,
          pollingInterval: 15000,
        });

        // Force a connection attempt
        await ethersProvider.ready;

        // Get network information
        const network = await ethersProvider.getNetwork();
        console.log("Detected network:", network);
        // setNetwork(network);
        return network;
      } catch (error) {
        console.error(
          `Network detection attempt ${4 - retryCount} failed:`,
          error
        );
        retryCount--;

        if (retryCount === 0) {
          setError(
            "Unable to detect network. Please check your connection and try again."
          );
          return null;
        }

        // Wait before retrying
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        console.log("Retrying network detection...");
      }
    }
  };

  const switchOrAddAmoyNetwork = async () => {
    if (!ethereum) {
      setError("MetaMask is not installed");
      setAlert(true);
      return;
    }
    const amoyNetworkDetails = {
      chainId: "0x13882",
      chainName: "Polygon Amoy Testnet",
      rpcUrls: ["https://rpc-amoy.polygon.technology/"],
      nativeCurrency: {
        name: "Polygon",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://amoy.polygonscan.com/"],
    };

    try {
      const currentNetwork = await detectNetwork();
      if (currentNetwork?.chainId === 80002) {
        console.log("Already connected to POLYGON AMOY TESTNET");
        return;
      }

      // Try switching to the network
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: amoyNetworkDetails.chainId }],
      });
    } catch (switchError) {
      // If the network is not available, add it
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: "wallet_addEthereumChain",
            params: [amoyNetworkDetails],
          });
          console.log("AMOY network added successfully!");
        } catch (addError) {
          console.error("Failed to add AMOY network:", addError);
          setError("Failed to add the AMOY network. Please try again.");
        }
      } else {
        console.error("Failed to switch to AMOY network:", switchError);
        setError("Failed to switch to the AMOY network. Please try again.");
      }
    }
  };

  const connectWallet = async () => {
    if (!ethereum) {
      setError("MetaMask not detected. Prompting user to install...");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const Amoy = await switchOrAddAmoyNetwork();
      const provider = new ethers.providers.Web3Provider(ethereum, "any");
      const accounts = await provider.send("eth_requestAccounts", []);
      if (!accounts || !accounts[0]) {
        throw new Error("No accounts returned");
      }

      const checksummedAddress = ethers.utils.getAddress(accounts[0]);
      await accountChangedHandler(checksummedAddress);
      console.log("Wallet connected and switched to AMOY network.");
    } catch (error) {
      console.error("Connection error:", error);
      setError(
        "Failed to connect or switch to the AMOY network. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const accountChangedHandler = async (newAccount) => {
    try {
      setDefaultAccount(newAccount);
      localStorage.setItem("address", newAccount);
      const provider = new ethers.providers.Web3Provider(ethereum, "any");
      await getUserBalance(newAccount);
      navigate("/dashboard");
    } catch (error) {
      console.error("Account change error:", error);
      setError("Failed to process account change");
    }
  };

  const getUserBalance = async (address) => {
    if (!ethereum || !address) return;

    setIsLoading(true);
    setError(null);

    try {
      const provider = new ethers.providers.Web3Provider(ethereum, "any");
      console.log("Fetching balance for address:", address);
      // const balance = await provider.getBalance(address);
      const balance =
        await process.env.REACT_APP_TOKEN_LOCKER_CONTRACT_ADDRESS.balanceOf(
          address
        );
      const formattedBalance = ethers.utils.formatEther(balance);
      console.log("Balance fetched:", formattedBalance);
      localStorage.setItem("balance", formattedBalance);
      setUserBalance(formattedBalance);
    } catch (error) {
      console.error("Balance fetch error:", error);
      setError("Failed to fetch balance. Please try again.");
      setUserBalance("Error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!ethereum) return;

    const handleAccountsChanged = async (accounts) => {
      try {
        if (accounts && accounts.length > 0) {
          const checksummedAddress = ethers.utils.getAddress(accounts[0]);
          await accountChangedHandler(checksummedAddress);
        } else {
          console.log("Wallet disconnected");
          setDefaultAccount("");
          setUserBalance("");
          setSigner(null);
          localStorage.removeItem("address");
          navigate(0);
          setError("Wallet disconnected");
        }
      } catch (error) {
        console.error("Account change handler error:", error);
      }
    };

    const handleChainChanged = () => {
      window.location.reload();
    };

    const handleDisconnect = () => {
      console.log("Wallet disconnected");
      setDefaultAccount("");
      setUserBalance("");
      setSigner(null);
      localStorage.removeItem("address");
      setError("Wallet disconnected");
      navigate("/");
    };

    try {
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("disconnect", handleDisconnect);
    } catch (error) {
      console.error("Error adding event listeners:", error);
    }

    return () => {
      try {
        if (ethereum.removeListener) {
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("disconnect", handleDisconnect);
        }
      } catch (error) {
        console.error("Error removing listeners:", error);
      }
    };
  }, [ethereum]);
  return {
    ethereum,
    defaultAccount,
    userBalance,
    signer,
    isLoading,
    error,
    connectWallet,
    getUserBalance,
    detectNetwork,
    switchOrAddAmoyNetwork,
    alert,
  };
};

export default useMetaMask;
