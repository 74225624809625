import React from "react";
import MainLayout from "../../components/MainLayout";
import { useMetaMask, useMetaMaskOnboarding } from "./MetaMaskthings";

const ProductIndex = () => {
  // Use the useMetaMask hook to manage MetaMask connection and related info
  const {
    buttonText,
    isDisabled,
    accounts,
    balance,
    network,
    onClick,
    connectMetaMask,
  } = useMetaMask();

  return (
    <MainLayout>
      <div>
        <div className="h-[50vh] w-full text-center text-black">
          <h2>Product</h2>

          <div className="mt-4">
            {/* Display MetaMask connection button */}
            <button
              className="p-2 bg-blue-500 text-white rounded"
              disabled={isDisabled}
              onClick={connectMetaMask}
            >
              {buttonText}
            </button>
          </div>

          {/* Show account and balance if the account is connected */}
          {accounts.length > 0 && (
            <div className="mt-4">
              <p>
                <strong>Account:</strong> {accounts[0]}
              </p>
              <p>
                <strong>Balance:</strong>{" "}
                {balance
                  ? `${parseInt(balance, 16) / 10 ** 18} ETH`
                  : "Loading..."}
              </p>
            </div>
          )}

          {/* Display network info if available */}
          {network && (
            <div className="mt-4">
              <p>
                <strong>Network:</strong>{" "}
                {network === "80002" ? "Amoy" : "Unknown Network"}
              </p>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ProductIndex;
