import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const WhatsHappening = () => {
  const [showLockTokenButton, setShowLockTokenButton] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowLockTokenButton(window.innerWidth <= 1024);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLockTokenClick = () => {
    const section = document.getElementById("lock-token-section");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="bg-whats-happening bg-center bg-no-repeat bg-cover w-full rounded-[10px] grid md:grid-cols-[60%_40%] gap-y-4 py-14 pl-4 pr-6">
      <div>
        <h2 className="text-[20px] font-semibold leading-[24px] text-[#FFFFFF]">
          What's happening?!!
        </h2>
        <p className="text-[18px] mt-8 font-normal leading-[24px] text-[#FFFFFF]">
          Join us at an exclusive token launch party and don't forget to grab
          some freshly baked cookies!
        </p>
      </div>
      <div className="flex items-end justify-center">
        {showLockTokenButton ? (
          <button
            onClick={handleLockTokenClick}
            className="bg-white hover:bg-[#f1f0f0] rounded-[10px] text-[#0E7570] font-semibold py-2 px-3"
          >
            Lock Token
          </button>
        ) : (
          <Link
            to="/dashboard/dao"
            className="bg-white hover:bg-[#f1f0f0] rounded-[10px] text-[#0E7570] font-semibold py-2 px-3"
          >
            View More
          </Link>
        )}
      </div>
    </div>
  );
};

export default WhatsHappening;
