import React from "react";
import workinProgressImg from "../../assets/work-in-progress.png";

const Reports = () => {
  return (
    <>
      <div className="w-full h-[60vh] flex justify-center items-center text-[30px] font-medium">
        <div>
          <img src={workinProgressImg} alt="workinProgress" />
          <p className="text-center font-semibold text-[20px] md:text-[40px]">
            WORK IN PROGRESS
          </p>
        </div>
      </div>
    </>
  );
};

export default Reports;
