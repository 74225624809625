import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getBalance } from "../services/balance";
import toast from "react-hot-toast";

const TokenLocked = () => {
  const tokenLockerContractAddress =
    process.env.REACT_APP_TOKEN_LOCKER_CONTRACT_ADDRESS;

  // State to store dynamic values
  const [values, setValues] = useState({
    currentTokenLocked: "0",
    currentRewardedUsers: "0",
    currentSupply: "10M",
    currentBurnedTokens: "0", // New burned tokens field
  });

  // Mock data arrays
  const rewardedUsersValues = ["0"];
  const supplyValues = [
    "10M",
    "15M",
    "20M",
    "30M",
    "40M",
    "50M",
    "70M",
    "80M",
    "100M",
  ];
  const burnedTokensValues = ["0"];

  useEffect(() => {
    // Dynamic updater for each value
    const updateValues = (valuesArray, key, delay = 300) => {
      valuesArray.forEach((value, index) => {
        setTimeout(() => {
          setValues((prev) => ({ ...prev, [key]: value }));
        }, index * delay);
      });
    };

    updateValues(rewardedUsersValues, "currentRewardedUsers");
    updateValues(supplyValues, "currentSupply");
    updateValues(burnedTokensValues, "currentBurnedTokens", 400);
  }, []);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["balance", tokenLockerContractAddress],
    queryFn: () => getBalance(tokenLockerContractAddress),
    enabled: !!tokenLockerContractAddress,
    onError: (error) => {
      toast.error("Failed to fetch balance.");
      console.error("Error fetching balance:", error);
    },
  });

  useEffect(() => {
    if (data?.balance) {
      setValues((prev) => ({ ...prev, currentTokenLocked: data.balance }));
      console.log("Fetched balance:", data.balance);
    }
  }, [data]);

  return (
    <div className="bg-[#004442] w-[100%] py-8 relative">
      <div className="h-[110px] w-full grid grid-cols-2 md:grid-cols-4 gap-y-4 justify-center items-center">
        <ValueDisplay
          title="Token Locked"
          value={isLoading ? "0" : values.currentTokenLocked}
        />

        <ValueDisplay
          title="Rewarded Users"
          value={values.currentRewardedUsers}
        />

        <ValueDisplay
          title="Initial Circulatory Supply"
          value={values.currentSupply}
        />

        <ValueDisplay
          title="Burned Tokens"
          value={values.currentBurnedTokens}
        />
      </div>
    </div>
  );
};

const ValueDisplay = ({ title, value }) => (
  <div className="flex flex-col gap-1">
    <span className="text-[20px] sm:text-[24px] md:text-[40px] leading-[20px] md:leading-[48.41px] text-[#FFFFFF] font-bold text-center">
      {value}
    </span>
    <span className="text-[12px] sm:text-[16px] text-center text-[#FFFFFF] leading-[19.36px] font-medium">
      {title}
    </span>
  </div>
);

export default TokenLocked;
