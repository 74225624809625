import React from "react";

const Linked = ({ bgColor = "black" }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50px"
        height="50px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="atoms/icons/32/social/a-ic-32-social-linkedin"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          {/* Background Rectangle */}
          {/* <rect width="32" height="32" fill={"white"} rx="4" /> */}
          {/* LinkedIn "in" Logo */}
          <path
            d="M24.1908377,6 L7.8091623,6 C6.81,6 6,6.81 6,7.8091623 L6,24.1907853 C6,25.19 6.81,26 7.8091623,26 L24.1907853,26 C25.19,26 26,25.19 26,24.1907853 L26,7.8091623 C26,6.81 25.19,6 24.1908377,6 Z M12.1888482,23.2693194 C12.1888482,23.5601047 11.9531414,23.7958115 11.662356,23.7958115 L9.42120419,23.7958115 C9.13041885,23.7958115 8.89471204,23.5601047 8.89471204,23.2693194 L8.89471204,13.8745026 C8.89471204,13.5837173 9.13041885,13.3480105 9.42120419,13.3480105 L11.662356,13.3480105 C11.9531414,13.3480105 12.1888482,13.5837173 12.1888482,13.8745026 L12.1888482,23.2693194 Z M10.5417801,12.4624084 C9.36591623,12.4624084 8.41267016,11.5091623 8.41267016,10.3332984 C8.41267016,9.15743455 9.36591623,8.20418848 10.5417801,8.20418848 C11.717644,8.20418848 12.6708901,9.15743455 12.6708901,10.3332984 C12.6708901,11.5091623 11.7176963,12.4624084 10.5417801,12.4624084 Z M23.9010485,23.3117277 C23.9010485,23.5790576 23.6842932,23.7958115 23.4169634,23.7958115 L21.0120419,23.7958115 C20.744712,23.7958115 20.5279581,23.5790576 20.5279581,23.3117277 L20.5279581,18.9050262 C20.5279581,18.247644 20.7207853,16.0243455 18.81,16.0243455 C17.3278534,16.0243455 17.0272251,17.5461257 16.9668586,18.2290576 L16.9668586,23.3117277 C16.9668586,23.5790576 16.7501571,23.7958115 16.4827749,23.7958115 L14.1568063,23.7958115 C13.8894764,23.7958115 13.6727225,23.5790576 13.6727225,23.3117277 L13.6727225,13.8320942 C13.6727225,13.5647644 13.8894764,13.3480105 14.1568063,13.3480105 L16.4827749,13.3480105 C16.7501047,13.3480105 16.9668586,13.5647644 16.9668586,13.8320942 L16.9668586,14.6517277 C17.5164398,13.8269634 18.3331937,13.1903665 20.072199,13.1903665 C23.923089,13.1903665 23.9010485,16.7880628 23.9010485,18.7648168 L23.9010485,23.3117277 L23.9010485,23.3117277 Z"
            fill={bgColor}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </div>
  );
};

export default Linked;
