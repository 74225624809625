import TokenQuantity from "../../components/clientDashboard/TokenQuantity";
import LockingPeriod from "../../components/clientDashboard/LockingPeriod";
import ProfitDistribution from "../../components/clientDashboard/ProfitDistribution";
import WhatsHappening from "../../components/clientDashboard/WhatsHappening";

export const DashboardIndex = () => {
  return (
    <>
      <div className=" grid lg:grid-cols-[68%_31%] xl:grid-cols-[75%_24%] gap-4">
        <div className="flex flex-col gap-4">
          <div>
            <WhatsHappening />
          </div>
          <div>
            <TokenQuantity />
          </div>
          <div>
            <ProfitDistribution />
          </div>
        </div>
        <div>
          <LockingPeriod />
        </div>
      </div>
    </>
  );
};
