import React, { useEffect, useState } from "react";
// import crossIcon from "../../../assets/gr";
import checkIcon from "../../../assets/check.svg";

const SuccessPopup = ({ message, isOpen, onClose }) => {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        onClose();
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-20 z-50">
          <div className="bg-white py-6 px-6  rounded-lg shadow-lg w-[300px] sm:w-[350px] h-[200px] sm:h-[250px] flex flex-col items-center justify-center gap-2 text-center">
            <img
              className="w-[90px] h-[90px] animate-zoomInOut"
              src={checkIcon}
              alt="checkIcon"
            />
            <p className="text-[#0E7570] text-[18px] font-medium">
              Token Locked Successfully !!
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessPopup;
