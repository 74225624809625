import React from "react";
import RoadmapItem from "./RoadMapItem";
import { Zoom } from "react-awesome-reveal";

const roadmapData = [
  {
    time: {
      month: "September",
      monthValue: 9,
      year: 2024,
    },
    tasks: [
      "Decide the key features, purpose, and the technological foundation of the coin",
      "Market research and analysis",
      "Study existing and past ICOs to identify gaps and opportunities",
    ],
  },
  {
    time: {
      month: "October",
      monthValue: 10,
      year: 2024,
    },
    tasks: [
      "Target audience identification",
      "Smart contract development for token sale",
      "UI/UX designing",
      "Prototype development",
    ],
  },
  {
    time: {
      month: "November",
      monthValue: 11,
      year: 2024,
    },
    tasks: [
      "Completing  milestones such as token locking and reward distribution",
      "Develop the brand identity including logo, exclusive merchandise, and marketing materials",
      "Internal testing of prototype",
      "Website launch",
    ],
  },
  {
    time: {
      month: "December",
      monthValue: 12,
      year: 2024,
    },
    tasks: [
      "Develop the brand identity including logo, exclusive merchandise, and marketing materials",
      "Social media and community engagement",
      "ICO launch (marketing campaigns, security audits)",
      "Token listing on multiple decentralized and centralized exchanges",
      "Final product launch",
    ],
  },
  {
    time: {
      month: "January",
      monthValue: 1,
      year: 2025,
    },
    tasks: [
      "Open the public sale of the tokens",
      "Provide real-time support to address potential investors' queries",
      "Keep investors posted regularly on the progress and milestones achieved",
      "Regular updates, continued community engagement, and transparent reporting practices",
    ],
  },
];

const Roadmap = () => {
  return (
    <div className="flex flex-col items-center  bg-white">
      <Zoom triggerOnce>
        <h2 className="text-4xl font-bold text-[#0E7570] mb-16">Roadmap</h2>
      </Zoom>
      <div className="relative w-[98%] sm:w-[90%] m-auto">
        <div className="hidden lg:block">
          <div className=" absolute top-0 left-1/2 transform -translate-x-1/2 h-full flex items-center">
            <div className=" w-0.5 bg-gray-300 h-[82%] xl:h-[80%]"></div>
          </div>
        </div>
        <Zoom triggerOnce>
          {roadmapData.map((item, index) => (
            <RoadmapItem
              key={index}
              time={item.time}
              tasks={item.tasks}
              isLeft={index % 2 === 0}
            />
          ))}
        </Zoom>
      </div>
    </div>
  );
};

export default Roadmap;
