import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export default function BarChart2({ data2 }) {
  const formatYAxis = (tick) => `${tick}m`; // Simplified arrow function

  return (
    <div>
      <ResponsiveContainer aspect={1} height={320}>
        <BarChart width={400} height={320} data={data2} barSize={40}>
          <XAxis dataKey="name" />
          {/* YAxis using a tickFormatter */}
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip />
          <Bar dataKey="value" fill="#17B0A9" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
