import { useState, useEffect, useRef } from "react";
import MetaMaskOnboarding from "@metamask/onboarding";

const ONBOARD_TEXT = "Install MetaMask";
const CONNECT_TEXT = "Connect MetaMask";
const CONNECTED_TEXT = "Connected";

export function useMetaMask() {
  const [buttonText, setButtonText] = useState(CONNECT_TEXT); // Default to Connect instead of Install
  const [isDisabled, setIsDisabled] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const onboarding = useRef(null);

  // Check if we're on mobile
  useEffect(() => {
    const checkMobile = () => {
      return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
        navigator.userAgent
      );
    };
    setIsMobile(checkMobile());
  }, []);

  // Initialize MetaMask Onboarding for desktop only
  useEffect(() => {
    if (!isMobile && !onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, [isMobile]);

  // Check if MetaMask is installed
  const isMetaMaskInstalled = () => {
    const { ethereum } = window;
    return Boolean(
      ethereum &&
        (ethereum.isMetaMask || ethereum.providers?.some((p) => p.isMetaMask))
    );
  };

  // Handle MetaMask state
  useEffect(() => {
    const updateButtonText = () => {
      if (accounts.length > 0) {
        setButtonText(CONNECTED_TEXT);
        setIsDisabled(true);
        if (!isMobile && onboarding.current) {
          onboarding.current.stopOnboarding();
        }
      } else if (isMetaMaskInstalled()) {
        setButtonText(CONNECT_TEXT);
        setIsDisabled(false);
      } else {
        setButtonText(ONBOARD_TEXT);
        setIsDisabled(false);
      }
    };

    updateButtonText();
  }, [accounts, isMobile]);

  // Handle account changes
  useEffect(() => {
    const setupAccountListeners = async () => {
      if (!isMetaMaskInstalled()) return;

      const provider =
        window.ethereum.providers?.find((p) => p.isMetaMask) || window.ethereum;

      const handleNewAccounts = (newAccounts) => {
        setAccounts(newAccounts);
      };

      try {
        const currentAccounts = await provider.request({
          method: "eth_accounts",
        });
        handleNewAccounts(currentAccounts);

        provider.on("accountsChanged", handleNewAccounts);

        return () => {
          provider.removeListener("accountsChanged", handleNewAccounts);
        };
      } catch (err) {
        console.error("Error setting up account listeners:", err);
      }
    };

    setupAccountListeners();
  }, []);

  // Connect to MetaMask
  const connectMetaMask = async () => {
    try {
      // Get the appropriate provider
      const provider =
        window.ethereum.providers?.find((p) => p.isMetaMask) || window.ethereum;

      if (!provider) {
        if (isMobile) {
          // Deep link to MetaMask if not installed on mobile
          const dappUrl = window.location.href;
          const metamaskAppDeepLink = `https://metamask.app.link/dapp/${dappUrl}`;
          window.location.href = metamaskAppDeepLink;
          return;
        } else {
          // Start onboarding for desktop
          onboarding.current?.startOnboarding();
          return;
        }
      }

      // Request accounts
      const newAccounts = await provider.request({
        method: "eth_requestAccounts",
      });
      setAccounts(newAccounts);
    } catch (err) {
      if (err.code === 4001) {
        console.log("User rejected connection");
      } else {
        console.error("Error connecting to MetaMask:", err);
      }
    }
  };

  return {
    buttonText,
    isDisabled,
    accounts,
    isConnected: accounts.length > 0,
    isMobile,
    connectMetaMask,
  };
}

// Example usage:
/*
function MetaMaskButton() {
  const { 
    buttonText, 
    isDisabled, 
    connectMetaMask,
    isConnected 
  } = useMetaMask();

  return (
    <button
      onClick={connectMetaMask}
      disabled={isDisabled}
      className={`px-4 py-2 rounded ${
        isConnected ? 'bg-green-500' : 'bg-blue-500'
      } text-white`}
    >
      {buttonText}
    </button>
  );
}
*/
