import React from "react";

const History = ({ fill = "#66666E" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H12"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8H17M7 12H11"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2918 18.7866C23.2915 19.733 23.0187 20.658 22.5079 21.4447C21.9972 22.2314 21.2714 22.8444 20.4223 23.2063C19.5733 23.5681 18.6391 23.6625 17.7379 23.4776C16.8368 23.2926 16.0091 22.8366 15.3596 22.1672C14.7101 21.4978 14.2679 20.645 14.089 19.7168C13.91 18.7885 14.0023 17.8265 14.3543 16.9523C14.7062 16.0781 15.3019 15.331 16.0661 14.8055C16.8303 14.28 17.7287 13.9997 18.6475 14C19.8793 14.0018 21.0601 14.5068 21.9308 15.4041C22.8015 16.3015 23.291 17.518 23.2918 18.7866ZM21.6657 18.7866C21.6657 18.1714 21.4885 17.57 21.1566 17.0585C20.8247 16.547 20.353 16.1484 19.8011 15.9131C19.2493 15.6777 18.642 15.6162 18.0562 15.7364C17.4704 15.8565 16.9323 16.1529 16.51 16.588C16.0878 17.0231 15.8003 17.5775 15.684 18.1809C15.5676 18.7843 15.6277 19.4097 15.8565 19.978C16.0853 20.5462 16.4726 21.0318 16.9694 21.3734C17.4662 21.7149 18.0502 21.8971 18.6475 21.8967C19.4479 21.8954 20.2151 21.5672 20.7808 20.9842C21.3466 20.4012 21.6648 19.6109 21.6657 18.7866ZM18.6475 16.1672C18.4319 16.1672 18.2251 16.2554 18.0726 16.4125C17.9201 16.5695 17.8345 16.7825 17.8345 17.0046V17.9492H17.4035C17.1879 17.9492 16.9811 18.0374 16.8286 18.1945C16.6761 18.3515 16.5905 18.5645 16.5905 18.7866C16.5905 19.0087 16.6761 19.2217 16.8286 19.3787C16.9811 19.5358 17.1879 19.624 17.4035 19.624H18.6475C18.8632 19.624 19.07 19.5358 19.2225 19.3787C19.3749 19.2217 19.4606 19.0087 19.4606 18.7866V17.0046C19.4606 16.7825 19.3749 16.5695 19.2225 16.4125C19.07 16.2554 18.8632 16.1672 18.6475 16.1672Z"
        fill={fill}
      />
    </svg>
  );
};

export default History;
