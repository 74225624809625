import React, { useEffect, useState, useRef } from "react";
import cuffi from "../assets/CUFFI.svg";
import map from "../assets/map.svg";
import { Link, NavLink } from "react-router-dom";
import linkArrow from "../assets/linkArrow.svg";
import menu from "../assets/Menu.svg";
import cross from "../assets/cross.svg";
import PopUp from "./ConnectMetaMask";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(localStorage.getItem("address"));

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  const [toggle, setToggle] = useState(false);
  const headerRef = useRef(null);

  const updateScreenSize = () => {
    setScreenSize({
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);
  useEffect(() => {
    const localAddress = localStorage.getItem("address");
    if (localAddress !== null) {
      setAddress(localAddress);
    }
  }, []);

  useEffect(() => {
    if (screenSize.width >= 640) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [screenSize.width]);
  const scrollToPosition = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickOutside = (event) => {
    if (
      headerRef.current &&
      !headerRef.current.contains(event.target) &&
      screenSize.width < 640
    ) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (toggle && screenSize.width < 640) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [toggle, screenSize]);

  return (
    <>
      <div
        ref={headerRef}
        className=" bg-white px-[5%] h-20 flex justify-between lg:grid lg:grid-cols-[40%_60%] items-center  sticky top-0  right-0 left-0 z-50"
      >
        <div className="cursor-pointer">
          <Link to="/">
            <img className="w-[70%] lg:w-auto" src={cuffi} alt="cuffiIcon" />
          </Link>
        </div>
        <div className="sm:hidden" onClick={() => setToggle(!toggle)}>
          {toggle ? (
            <img src={cross} alt="crossIcon" />
          ) : (
            <img src={menu} alt="menuIcon" />
          )}
        </div>
        {toggle ? (
          <div
            className={
              "h-auto pt-4 pb-6 sm:pt-0 sm:pb-0 flex flex-col sm:flex-row gap-4 sm:pl-10  absolute sm:static top-[100%] left-0 right-0 sm:top-0 bg-[#ddf0f0] sm:bg-white sm:flex sm:justify-between sm:h-full sm:items-center"
            }
          >
            <div className="">
              <ul className="flex flex-col pl-4 sm:pl-0 sm:flex-row gap-4 sm:gap-8 md:gap-12 list-none text-[16px] font-normal text-[#66666E] cursor-pointer">
                <NavLink onClick={scrollToPosition} to="/">
                  Home
                </NavLink>
                <NavLink
                  onClick={scrollToPosition}
                  to="/about"
                  className="flex gap-1"
                >
                  About Us
                </NavLink>
                {address && (
                  <NavLink
                    onClick={scrollToPosition}
                    to="/dashboard"
                    className="flex gap-1"
                  >
                    Dashboard
                  </NavLink>
                )}
              </ul>
            </div>
            <div>
              {!address && (
                <div className="flex gap-4 sm:gap-2 md:gap-4 justify-center sm:justify-end mt-8 sm:mt-0">
                  <button
                    onClick={() => setOpen(true)}
                    className="px-3 py-[12px] bg-[#0E7570] hover:bg-[#004442] rounded-[6px]  text-white font-semibold text-[16px] sm:text-[12px] md:text-[16px] leading-[19.36px]"
                  >
                    Connect Wallet
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <PopUp open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
