import React from "react";
import image from "../../assets/AboutUs.png";
import { Zoom } from "react-awesome-reveal";

const HeroSection = () => {
  return (
    <>
      <div className="w-full h-full flex items-center justify-center pt-2 lg:pt-8">
        <Zoom triggerOnce>
          <h2 className="text-[32px] text-[#0E7570] font-bold lg:mt-4">
            About Us
          </h2>
        </Zoom>
      </div>
    </>
  );
};

export default HeroSection;
