import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";

const stepsData = [
  {
    step: "Step 1",
    description: "Buy Cuffi tokens from Uniswap",
    borderColor: "border-l-[#D5F895]",
  },
  {
    step: "Step 2",
    description: "Tokens will be credited into your metamask wallet",
    borderColor: "border-l-[#8AAEFA]",
  },
  {
    step: "Step 3",
    description:
      "Go to cuffi dashboard and lock tokens for a particular time period to earn rewards",
    borderColor: "border-l-[#FCAEF2]",
  },
  {
    step: "Step 4",
    description:
      "Quaterly rewards will be calculated after deducting all expenses from the revenue",
    borderColor: "border-l-[#FCAECB]",
  },
  {
    step: "Step 5",
    description:
      "Rewards will be credited automatically to your wallet address depending upon tokens locked and time period",
    borderColor: "border-l-[#EEF987]",
  },
  {
    step: "Step 6",
    description:
      "You can choose to stay invested or unlock tokens after completion of your locking period",
    borderColor: "border-l-[#8AF9FC]",
  },
];

const CuffiPortfolio = () => {
  return (
    <div className="bg-white w-[90%] mx-auto">
      <Fade triggerOnce direction="down">
        <h2 className="text-[#0E7570] font-bold text-[20px] lg:text-[32px] leading-[30px] lg:leading-[46px] text-center">
          Start building your cuffi
        </h2>
        <h2 className="text-[#0E7570] font-bold text-[20px] lg:text-[32px] leading-[30px] lg:leading-[46px] text-center">
          portfolio in 6 steps
        </h2>
      </Fade>

      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-8 mt-10">
        {stepsData.map((step, index) => (
          <Zoom triggerOnce>
            <div
              key={index}
              className={`px-5 py-6 sm-py-0 sm-px-0 sm-p-8 min-h-[170px] shadow-dao-shadow flex flex-col gap-4 rounded-[10px] border-l-8 ${step.borderColor}`}
            >
              <p className="text-[18px] text-[#0F8580] font-semibold leading-[21.78px]">
                {step.step}
              </p>
              <p className="text-[15px] md:text-[18px] lg:text-[22px] text-[#0A0A0A] font-normal leading-[24px] lg:leading-[30px] ">
                {step.description}
              </p>
            </div>
          </Zoom>
        ))}
      </div>
    </div>
  );
};

export default CuffiPortfolio;
