import React, { useState } from "react";
import TokenLocked from "../../components/TokenLocked";
import spoon from "../../assets/spoon.png";
import { Link } from "react-router-dom";
import PopUp from "../../components/ConnectMetaMask";
import uniswap from "../../assets/uniswap.svg";
import { Zoom } from "react-awesome-reveal";
const HeroSection = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="relative">
        <div
          className="bg-home-heroBg  bg-no-repeat bg-cover bg-center w-full
         relative  py-24  sm:py-32 lg:py-40"
        >
          <Zoom triggerOnce>
            <div className="h-full w-full flex flex-col items-center">
              <h2 className=" text-[20px] md:text-[32px] lg:text-[46px] leading-[20px] sm:leading-[40px] md:leading-[64px] font-semibold">
                Have a SIP, Buy the DIP
              </h2>
              <p className="text-[#66666E] font-normal md:text-[18px] leading-[26px] text-center sm:w-[60%] lg:w-[45%] m-auto px-4 md:px-0 pt-8">
                Coffee lovers can earn rewards just by being a part of the
                coffee token economy.
              </p>
              <p className="text-[#66666E] font-normal md:text-[18px] leading-[26px] text-center md:w-[60%] lg:w-[45%] m-auto px-4 md:px-0 pt-4">
                Brew rewards with CUFFI
              </p>
              {/* <button
              onClick={() => setOpen(true)}
              className=" bg-[#0E7570] flex gap-1 hover:bg-[#004442] mt-8 text-white rounded-[6px] font-semibold px-3 py-2  relative z-20"
            >
              Buy now on Uniswap <img src={uniswap} alt="uniswap" />
            </button> */}
            </div>
          </Zoom>
          <div className="">
            <img
              className="w-[30%] sm:w-auto absolute bottom-[-8%] sm:bottom-[-17%]  md:bottom-[-16%] z-10 right-0"
              src={spoon}
              alt="spoon"
            />
          </div>
        </div>

        <TokenLocked />
        <div className="absolute top-0 left-0 right-0">
          <PopUp open={open} setOpen={setOpen} />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
