import React from "react";
import LatestNews from "../../components/clientDashboard/LatestNews";

const Dao = () => {
  return (
    <>
      <div>
        <div className="bg-[#0E75701A] opacity-90 w-full text-center text-[#0E7570] font-normal text-[16px] md:text-[22px] lg:leading-[26px] py-2 rounded-[10px] px-5">
          Welcome to CoffeeDAO - Where Coffee Meets Blockchain!
        </div>

        <div className="grid  gap-5 mt-6">
          <div className="flex flex-col gap-4">
            <div className="shadow-dao-shadow p-5 flex flex-col gap-4 rounded-[10px]">
              <h3 className="text-[#0E7570] font-semibold text-[20px] leading-[24px]">
                Purpose & Vision
              </h3>
              <p>
                The CuffiDAO governs the cuffi token ecosystem, allowing token
                holders to make key decisions regarding the project's
                development, partnerships, rewards distribution and more. The
                primary goals are to:
              </p>
              <ul className="list-disc pl-7 text-[#0A0A0A] text-[16px] font-normal leading-[19px] flex flex-col gap-4">
                <li>Foster a strong, engaged coffee-loving community.</li>
                <li>
                  Ensure the decentralized growth of the coffee ecosystem.
                </li>
                <li>
                  Promote sustainable coffee sourcing and support small coffee
                  growers.
                </li>
                <li>
                  Offer voting rights on future developments, marketing
                  strategies, and token economics.
                </li>
              </ul>
            </div>

            <div className="shadow-dao-shadow p-5 flex flex-col gap-4 rounded-[10px]">
              <h3 className="text-[#0E7570] font-semibold text-[20px] leading-[24px]">
                Governance Model
              </h3>
              <p>
                The CuffiDAO will follow a token-weighted voting model where
                decision-making is proportional to the number of tokens held by
                each member. This ensures that active participants and
                stakeholders in the ecosystem have a say in its direction.
              </p>
            </div>

            <div className="shadow-dao-shadow p-5 flex flex-col gap-4 rounded-[10px]">
              <h3 className="text-[#0E7570] font-semibold text-[20px] leading-[24px]">
                Benefits of CoffeeDAO:
              </h3>
              <ul className="list-disc pl-7 text-[#0A0A0A] text-[16px] font-normal leading-[19px] flex flex-col gap-4">
                <li>
                  Community Empowerment: By decentralizing decision-making, you
                  give your coffee-loving community control over the project's
                  direction.
                </li>
                <li>
                  Transparency: All decisions and fund allocations are visible
                  and voted on by the community.
                </li>
                <li>
                  Sustainability: Through proposals and the treasury, CoffeeDAO
                  can focus on eco-friendly and ethical coffee production.
                </li>
                <li>
                  Offer voting rights on future developments, marketing
                  strategies, and token economics.
                </li>
                <li>
                  Rewards: Token holders benefit from staking rewards and get a
                  say in how the project grows, providing both financial and
                  participatory incentives.
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="h-auto">
            <LatestNews />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dao;
