import React from "react";
import pieChart from "../../assets/pie-chart.svg";
import blueDot from "../../assets/blueDot.svg";
import blackDot from "../../assets/blackDot.svg";
import purpolDot from "../../assets/purpol.svg";
import yellowDot from "../../assets/yellow.svg";
import orangeDot from "../../assets/orange.svg";
import brownDot from "../../assets/Brown.svg";
import skyBlueDot from "../../assets/skyBlue.svg";
import greenDot from "../../assets/greenDot.svg";
import lightBlue from "../../assets/lightBlue.svg";
import lightGreen from "../../assets/lightGreenDot.svg";
import PieChart from "../../components/PieChart";
import { Slide, Zoom, Fade, JackInTheBox } from "react-awesome-reveal";

const TokenEconomics = () => {
  const data1 = [
    {
      dotImage: brownDot,
      leftText: "Initial Circulatory Supply:",
      rightText: "10%",
    },
    {
      dotImage: lightGreen,
      leftText: "Liquidity & Exchange Listings:",
      rightText: "15%",
    },
    {
      dotImage: lightBlue,
      leftText: "Community & Airdrop:",
      rightText: "1%",
    },
    {
      dotImage: greenDot,
      leftText: "Reserved Supply:",
      rightText: " [74%]",
    },
  ];

  const data2 = [
    {
      dotImage: orangeDot,
      text: "Team Allocation: 7%",
    },
    {
      dotImage: blueDot,
      text: "Advisors & Partners: 7%",
    },
    {
      dotImage: skyBlueDot,
      text: "Ecosystem Growth: 10%",
    },
    {
      dotImage: yellowDot,
      text: "Marketing & Development: 10%",
    },

    {
      dotImage: purpolDot,
      text: "Dividend Rewards: 40%",
    },
  ];
  return (
    <>
      <div className="bg-white  w-[86%] m-auto">
        <Zoom triggerOnce>
          <h2 className="font-bold text-[20px] md:text-[32px] leading-[46px] text-[#0E7570] text-center">
            Token Economics
          </h2>
        </Zoom>
        <div className="grid lg:grid-cols-12 gap-y-10 lg:gap-y-0 w-full py-8">
          <div
            id="pieChart"
            className="lg:col-span-7 w-full flex justify-center"
          >
            <PieChart />
          </div>

          <div className="lg:col-span-5 grid lg:mt-4">
            <Zoom triggerOnce>
              <div className="place-self-center lg:place-self-end">
                {data1.map((data, index) => (
                  <div key={index} className="flex gap-1 mb-3">
                    <img src={data.dotImage} />
                    <span className="text-[#0A0A0A] font-semibold text-[10px] sm:text-[12px] md:text-[18px] leading-[21.78px]  ">
                      {data.leftText}
                    </span>
                    <span className="text-[#0A0A0A] font-normal text-[8px] sm:text-[12px] md:text-[16px] leading-[19.36px]">
                      {data.rightText}
                    </span>
                  </div>
                ))}

                <div className="pl-10">
                  {data2.map((data, index) => (
                    <div key={index} className="flex gap-1 mb-4">
                      <img src={data.dotImage} />
                      <span className="text-[#0A0A0A] font-normal text-[10px] md:text-[16px] leading-[21.78px]">
                        {data.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenEconomics;
