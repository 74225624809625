import React from "react";
const TokenLockPopup = ({ isOpen, onClose }) => {
  return (
    <>
      <div
        className="fixed inset-0 flex  justify-center items-center bg-black bg-opacity-20 z-50"
        // onClick={onClose}
      >
        <div
          className="bg-white py-6  px-6  rounded-lg shadow-lg w-[300px] sm:w-[350px] h-[200px] sm:h-[250px] flex flex-col justify-center items-center gap-2 relative"
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-[#0E7570] text-[18px] font-medium">
            Cuffi token is getting locked...
          </p>
          <p>Please wait</p>
          <div className="w-12 h-12 border-4 border-t-4 border-gray-200 rounded-full animate-spin border-t-[#0E7570]"></div>
        </div>
      </div>
    </>
  );
};

export default TokenLockPopup;
