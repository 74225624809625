import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast"; // For error notifications.
import { getUserDetails } from "../../services/userDetails";
import Pagination from "../../components/Pagination";

const History = () => {
  const [page, setPage] = useState(1);
  const limit = 9;
  const [userDetails, setUserDetails] = useState(null);
  const [address, setAddress] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const storedAddress = localStorage.getItem("address");
    if (storedAddress) {
      setAddress(storedAddress);
      console.log("Stored address:", storedAddress);
    }
  }, []);

  const {
    data,
    isLoading,
    isError,
    error: queryError,
  } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUserDetails(address),
    enabled: !!address,
    onError: (error) => {
      console.error("Error fetching user details:", error);
      setError(error.message || "Unknown error occurred.");
    },
  });

  useEffect(() => {
    if (data) {
      const reversedData = data.events ? [...data.events].reverse() : [];
      setUserDetails(reversedData);
      console.log("Fetched and reversed user details:", reversedData);
    }
  }, [data]);

  return (
    <>
      <div className="bg-[#FFFFFF] p-2 sm:p-8 shadow-custom rounded-[10px]">
        <div className="relative overflow-x-auto py-4">
          <table className="w-full text-[16px] text-[#0A0A0A] font-normal text-center">
            <thead className="text-[16px] font-medium leading-4 bg-[#9999A11A] text-[#66666E]">
              <tr>
                <th className="p-4 min-h-[26px]">TransactionHash</th>
                <th className="p-4 min-h-[26px]">Amount</th>
                <th className="p-4 min-h-[26px]">Time-Period</th>
                <th className="p-4 min-h-[26px]">ReleaseDate</th>
              </tr>
            </thead>
            <tbody>
              {userDetails
                ?.slice((page - 1) * limit, page * limit)
                .map((event) => (
                  <tr key={event._id} className="bg-white">
                    <td className="py-3">
                      <a
                        href={`https://www.oklink.com/amoy/tx/${event.transactionHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                        title="Check on explorer"
                      >
                        {`${event.transactionHash.slice(
                          0,
                          8
                        )}...${event.transactionHash.slice(-8)}`}
                      </a>
                    </td>
                    <td className="py-3">
                      {(event.data.amount / 100).toFixed(2)}
                    </td>
                    <td className="py-3">{event.data.lockingPeriod} days</td>
                    <td className="py-3">{event.data.releaseDate}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {isLoading && (
            <div className="w-full h-[400px] flex justify-center items-center">
              <div className="w-12 h-12 border-4 border-t-4 border-gray-200  rounded-full animate-spin border-t-[#d9dbdb]"></div>
            </div>
          )}

          {isError && (
            <div className="w-full h-[400px] flex justify-center items-center text-red-600">
              <p>
                {error || queryError?.message || "An unknown error occurred."}
              </p>
            </div>
          )}
          {!isLoading && !isError && userDetails?.length === 0 && (
            <div className="w-full h-[400px] flex justify-center items-center text-gray-500">
              <p>No transaction history found for this address.</p>
            </div>
          )}
        </div>
        <Pagination
          datalength={userDetails?.length || 0}
          page={page}
          setPage={setPage}
          limit={limit}
        />
      </div>
    </>
  );
};

export default History;
